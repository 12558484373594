import './App.css';
import { motion } from 'framer-motion'
import { useState } from 'react';
import { Helmet } from 'react-helmet';

import Typed from 'react-typed';

function App() {


  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div layout className="App">
      <header className="App-header">
        <Helmet>
          <title>{"hi, im rickard!"}</title>
        </Helmet>


        <h1 class="gradient_1">
          hi, i'm rickard!
        </h1>
        <p><b class=" invisible">🧮</b></p>

        <p><b class=" invisible">🧮</b><a class="text2 gradient_2">I'm a</a>
          <Typed
            strings={[
              "<a class='text2 gradient_2'> full stack kind of person</a><b>🧑‍💻</b>",
              "<a class='text2 gradient_2'> rustacean</a><b>🦀</b>",
              "<a class='text2 gradient_2'> math enjoying fella</a><b>🧮</b>",
              "<a class='text2 gradient_2'>n open source lad</a><b>🔓</b>",
              "<b class='text2 gradient_2'> :)</b>"
            ]}
            typeSpeed={50}
            backSpeed={30}
            loop
          />


        </p>
        <p><b class=" invisible">🧮</b></p>
        <p><b class=" invisible">🧮</b></p>

        <motion.a layout className="card card1" href="mailto:rmarte@kth.se" rel="noopener noreferrer" title="email me at rmarte@kth.se">

          {isOpen && <motion.p>📩</motion.p>}
          {!isOpen && <motion.p>📧</motion.p>}

        </motion.a>

        <a layout className="card" href="https://github.com/Rickard-Martensson" rel="noopener noreferrer" title="i'm on github as Rickard Martensson">
          {isOpen && <motion.p>🖥️</motion.p>}
          {!isOpen && <motion.p>💻</motion.p>}
        </a>

        <a layout className="card" href="https://www.linkedin.com/in/rickard-m%C3%A5rtensson-b7b2ab129" rel="noopener noreferrer" title="connect with me on linkedIn">
          <motion.p>💼</motion.p>
        </a>

        <a layout className="card" href="https://ric.ke/sidor/rickard-mrtensson-resume.pdf" rel="noopener noreferrer" title="read my resumé!">
          <motion.p>📃</motion.p>
        </a>

        <motion.a title="(click me) see my projects!" layout onClick={() => setIsOpen(!isOpen)} className="card cardWhite">
          <motion.p>🕹️</motion.p>
          {isOpen &&
            <motion.p>
              <a class="gameIcon" href="https://ric.ke/sidor/vertiball/" title="vertiball, a 1v1 platformer!">🏐</a>
              <a class="gameIcon" href="https://littlewargame.com" title="littlewargame, a multiplayer RTS I'm contributing to">🏰</a>
              <a class="gameIcon" href="https://ric.ke/sidor/bob/" title="battles of bologna, a multiplayer RTS I made">⚔️</a>
              <a class="gameIcon" href="https://ric.ke/sidor/poker/" title="poker calculator">🃏</a>
              <a class="gameIcon" href="https://ric.ke/sidor/haj/" title="shark deaths">🦈</a>
              <a class="gameIcon" href="https://ric.ke/sidor/oxfordspel3/" title="open world game i got paid to make">🏘️</a>
              <a class="gameIcon" href="https://ric.ke/sidor/flort/" title="love compatibility tester. slightly biased">💞</a>
              <a class="gameIcon" href="https://ric.ke/sidor/ponken/" title="bee movie">🐝</a>
            </motion.p>}
        </motion.a>

        <motion.a layout className="card" href="https://www.researchgate.net/profile/Rickard-Martensson" rel="noopener noreferrer" title="scroll trough my papers">
          <motion.p>📑</motion.p>
        </motion.a>



      </header>

    </motion.div >
  );
}

export default App;
